import { defineStore } from 'pinia'
import { useCookieStore } from "~/store/cookie";
import {transform} from "~/utils/cache";

export const useAuthStore = defineStore({
  id: 'auth-store',
  state: () => {
    return {
      returnUrl: false,
      errors: null,
      fetchError: false,
      subscriber: null,
      pingError: false,
    }
  },
  actions: {
    async ping(config) {
        this.pingError = false;
        const resp = await $fetch( `${config.public.externalApiBase}/api/ping/`, {
            method: 'GET',
            server: false,
        }).catch((error) => {
          this.pingError = true;
        });

    },
    async login(username, password, config) {
        this.errors = null;
        const cookieStore = useCookieStore();
        const {data, error} = await useFetch(config.public.externalApiBase +`/api/token-auth/`, {
            method: 'POST',
            body: {
                username, password
            },
            server: false
        })

        if (data.value) {
            cookieStore.token = data.value.token;
        }

        if (error.value) {
            this.errors = error.value.data
        }
    },
    /*
    async socialLogin(provider, code, config) {
        this.errors = null;
        const cookieStore = useCookieStore();
        const resp = await $fetch(config.public.externalApiBase +`/api/auth/${provider}/`, {
            method: 'POST',
            body: {
                code
            },
            server: false
        }).catch((error) => {
          if (error.response._data && error.response._data.non_field_errors) {
            this.errors = error.response._data.non_field_errors
          }
        })

        if (resp) {
            cookieStore.token = resp.key;
        }
    },
    async socialConnect(provider, code, config) {
        this.errors = null;
        const cookieStore = useCookieStore();
        const resp = await $fetch(config.public.externalApiBase +`/api/connect/${provider}/`, {
            method: 'POST',
            body: {
                code
            },
            headers: { 'Authorization': 'Token ' + cookieStore.token},
            server: false
        }).catch((error) => {
          if (error.response._data && error.response._data.non_field_errors) {
            this.errors = error.response._data.non_field_errors
          }
        })

        if (resp) {
            cookieStore.token = resp.key;
        }
    },
    async disconnectSocial(provider_id, config) {
        this.errors = null;
        const cookieStore = useCookieStore();
        const resp = await $fetch( `${config.public.externalApiBase}/api/disconnect/${provider_id}/`, {
            method: 'POST',
            headers: { 'Authorization': 'Token ' + cookieStore.token},
            server: false
        }).catch((error) => {
          if (error.response._data && error.response._data.non_field_errors) {
            this.errors = error.response._data.non_field_errors
          }
        })

        if (resp) {
            cookieStore.token = resp.key;
        }
    },*/
    async resetPassword(email, config) {
        this.errors = null;
        const {data, error} = await useFetch(config.public.externalApiBase +`/rest-auth/password/reset/`, {
            method: 'POST',
            body: {
                email
            },
        })

        if (error.value) {
            this.errors = error.value.data
        }
    },
    async confirmResetPassword(token, uid, new_password1, new_password2, config) {
        this.errors = null;
        const {data, error} = await useFetch(config.public.externalApiBase +`/rest-auth/password/reset/confirm/`, {
            method: 'POST',
            body: {
                token, uid, new_password1, new_password2
            },
        })

        if (error.value) {
            this.errors = error.value.data
        }
        else {
          await this.refreshToken(config)
        }
    },
    async signup(username, password, password2, join_mailing_list, coupon_code, config) {

        this.errors = null;
        const cookieStore = useCookieStore();
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/register/`, {
            method: 'POST',
            body: {
                username, password, password2, join_mailing_list, coupon_code
            },
        })

        if (error.value) {
            if (error.value.data && error.value.data.non_field_errors) {
                this.errors = { password: error.value.data.non_field_errors[0]}
            } else {
                this.errors = error.value.data
            }
        }
    },
    async changePassword(old_password, new_password, config) {
      this.errors = null;
      const cookieStore = useCookieStore();
      const {data, error} =
          await useFetch(`${config.public.externalApiBase}/api/change-password/`, {
          method: 'PUT',
          headers: { 'Authorization': 'Token ' + cookieStore.token},
          body: {
              old_password, new_password
          }
      });

      if (error.value) {
        if (error.value.data && error.value.data.non_field_errors) {
            this.errors = { password: error.value.data.non_field_errors[0]}
        } else {
            this.errors = error.value.data
        }
      }
      else {
        await this.refreshToken(config)
      }
    },
    async userDetails(config) {
      this.fetchError = false;
      const nuxtApp = useNuxtApp();
      const cookieStore = useCookieStore();
      const {data, error} = await useFetch( `${config.public.externalApiBase}/api/my-details/`, {
          method: 'GET',
          headers: { 'Authorization': 'Token ' + cookieStore.token},
          transform: transform,
          getCachedData(key) {
            return checkCache(key, 1000 * 60 * 3);
          }
      })

      if (data.value) {
          cookieStore.user = data.value;
      }
      if (error.value) {
          this.fetchError = true
      }
    },
    async userDetailsNoSave(config) {
      const cookieStore = useCookieStore();
        const {data, error} = await useFetch( `${config.public.externalApiBase}/api/my-details/`, {
            method: 'GET',
            headers: { 'Authorization': 'Token ' + cookieStore.token}
        })

        if (data.value) {
            return data.value;
        }

        return null;
    },
    async refreshToken(config) {
        const cookieStore = useCookieStore();
        const {data, error} = await useFetch( `${config.public.externalApiBase}/api/auth/refresh-token/`, {
            method: 'POST',
            headers: { 'Authorization': 'Token ' + cookieStore.token}
        })

        if (data.value) {
            cookieStore.token = data.value.token;
        }
    },
    logout() {
        const cookieStore = useCookieStore();
        cookieStore.token = null;
        cookieStore.user = null;
        window.location.href = '/'; // reload the page
    },
    async confirmEmail(key, config) {
        const resp = await $fetch(`${config.public.externalApiBase}/api/confirm-email/`, {
            method: 'POST',
            body: {
                key
            }
        }).catch((error) => {
          this.errors = error.response._data

        })
    },
    async resendConfirmationEmail(email, config) {
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/resend-email-verification/`, {
            method: 'POST',
            body: {
                email
            },
        })

        if (error.value) {
            this.errors = error.value.data
        }
    },
    async mailingListSubscribe(email, name, utm_campaign, utm_source, utm_medium, config) {
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/join-mailing-list/`, {
            method: 'POST',
            body: {
              email,
              name,
              utm_campaign,
              utm_source,
              utm_medium
            },
        })

        if (error.value) {
          if (error.value.data.error) {
            this.errors = { name: error.value.data.error}
          }
        }
    },
    async mailingListGetStatus(email, config) {
        const resp = await $fetch(`${config.public.externalApiBase}/api/get-subscriber/`, {
            method: 'GET',
            params: {
              email
            },
        }).catch((error) => {
          this.fetchError = true
          console.log(error)
        });

        if (resp) {
          this.subscriber = resp;
        }

    },
    async mailingListUnsubscribe(email, config) {
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/mailerlite-unsubscribe/`, {
            method: 'GET',
            params: {
              email
            },
        })

        if (data.value) {
          this.subscriber = data.value;
        }

        if (error.value) {
          this.fetchError = true
        }
    },
    async mailingListResubscribe(email, config) {
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/mailerlite-resubscribe/`, {
            method: 'GET',
            params: {
              email
            },
        })

        if (data.value) {
          this.subscriber = data.value;
        }

        if (error.value) {
          this.fetchError = true
        }
    },
    async notificationsUpdate(video_notifications, transcription_notifications, config) {
        const cookieStore = useCookieStore();
        const {data, error} = await useFetch(`${config.public.externalApiBase}/api/user-notifications/`, {
            method: 'PUT',
            headers: { 'Authorization': 'Token ' + cookieStore.token},
            body: {
              video_notifications,
              transcription_notifications
            },
        })

        if (error.value) {
          this.fetchError = true
        }
    }
  },
  getters: {
  },
})
